import React, {Component} from 'react';
import {connect} from 'react-redux';

import {Loading} from "@components/loading";
import {LinkStatus} from "@components/link-status";
import {Header} from "@components/questionnaire/header";
import {withTranslation} from "react-i18next";
import {LangSwitcher} from "@components/form/lang-switcher";
import {Button} from "@components/form/button";
import {actions as questionnaireActions} from "@store/sagas/questionnaire";
import {DynamicTypography} from "@components/DynamicTypography";
import {withRouter} from "@utils/router-util";

class Consent extends Component {

    componentDidMount() {
        if (!this.props.link) {
            const {token, promId} = this.props.match.params;
            this.props.requestLink(token, promId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.i18n.language !== this.props.language) {
            this.props.i18n.changeLanguage(this.props.language);
        }
    }

    onContinue = () => {
        this.props.navigate(`/questionnaire/${this.props.match.params.token}/start`, {replace: true});
    }

    render() {
        if (this.props.loading) {
            return <Loading/>
        }

        if (this.props.error) {
            return <LinkStatus type="error"/>;
        }

        if (this.props.status === 'denied') {
            return <LinkStatus type="denied"/>;
        }

        return (
            <div className="container">
                <Header title={this.props.t('common:welcomePage.title')} className={'consent-header'} pii={{firstName: this.props.patient.firstName, lastName: this.props.patient.lastName}} />
                <div className="consent-container">
                    <LangSwitcher active={this.props.i18n.language} onChange={this.props.setLang}/>

                    <div className="consent-welcome">
                        <DynamicTypography content={this.props.t('common:welcomePage.p1')} />
                        <DynamicTypography content={this.props.t('common:welcomePage.p2')} />
                    </div>

                    <div className="consent-actions">
                        <p>{this.props.t('common:welcomePage.consent')}</p>

                        <div className="consent-buttons">
                            <Button title={this.props.t('common:welcomePage.no')} type={'secondary'}
                                    onClick={() => this.props.setStatus('denied')}/>
                            <Button title={this.props.t('common:welcomePage.yes')}
                                    type={this.props.status && this.props.status === 'yes' ? 'primary' : 'secondary'}
                                    onClick={() => this.props.setStatus('yes')}/>
                        </div>

                        {this.props.status && this.props.status === 'yes' && (
                            <div className="survey-continue">
                                <p>{this.props.t('common:welcomePage.hint')}</p>
                                <Button title={this.props.t('common:welcomePage.continue')} type={'primary'}
                                        onClick={this.onContinue}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    error: state.app.questionnaire.error,
    patient: state.app.questionnaire.patient,
    link: state.app.questionnaire.link,
    status: state.app.questionnaire.status,
    loading: state.app.questionnaire.loading,
    language: state.app.questionnaire.language,
});
const mapDispatchToProps = (dispatch) => ({
    requestLink: (token, promId) => dispatch(questionnaireActions.requestLink({token, promId})),
    setStatus: (status) => dispatch(questionnaireActions.setStatus(status)),
    setLang: (lang) => dispatch(questionnaireActions.setLanguage(lang)),
});
export default withRouter(withTranslation()(connect(mapStateToProps, mapDispatchToProps)(Consent)));
