import React, {useEffect, useState} from 'react';
import {BackgroundLayout} from './BackgroundLayout';
import {NavigationButtonV2} from '../NavigationButtonV2';
import styled from 'styled-components';

const ProgressBar = styled.div`
    background-color: #5FCD89;
    height: 5px;
    width: ${props => ((props.currentPage + 1) / props.totalPages) * 100}%;
`;

export const FormLayout = ({
  children,
  onBack,
  onNext,
  prevLabel,
  nextLabel,
  submitLabel,
  activeTab,
  state,
  iconStyle,
  isValid,
  currentPage,
  totalPages,
}) => {
  const [isChanging, setIsChanging] = useState(false);

  useEffect(() => {
    if (state !== isChanging) {
      setIsChanging(state);
    }
  }, [state, isChanging]);

  const NextButton = () => {
    if (activeTab !== 'overview') {
      return (
        <NavigationButtonV2
          onPress={onNext}
          label={currentPage + 1 === totalPages ? submitLabel : nextLabel}
          valid={isValid}
          disabled={!isValid}
          direction={'right'}
        />
      );
    }
    return null;
  };

  return (
    <BackgroundLayout
      innerStyle={`
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;
    `}
      iconStyle={iconStyle}
      transparent={true}
      safeEdges={['top', 'left', 'right']}
      showIcon={false}>
      <div className={'form-layout-container'}>
        <div className={'header'}>
          <div className={`headerLeft`}>
            <img
              src={require('./../../assets/images/lhg-logo.png')}
              className={'logo'}
              alt="LHG Logo"
            />
          </div>
        </div>
        <div className={`content ${isChanging? 'contentOut': 'contentIn'}`}>{children}</div>
        <div className={'footer'}>
          <div className={'progressBar'}>
            <ProgressBar
                currentPage={currentPage}
                totalPages={totalPages}
            />
          </div>
          <div className={'footerContainer'}>
            <NavigationButtonV2
                onPress={onBack}
                label={prevLabel}
                direction={'left'}
            />
            <NextButton />
          </div>
        </div>
      </div>
    </BackgroundLayout>
  );
};
