import React from 'react';
import styled, { css } from 'styled-components';

const InnerContainer = styled.div`
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 100px;
    flex: 1;
    display: flex;
    
    ${props => props.innerStyle && css`${props.innerStyle}`}
`;

const Icon = styled.img`
    position: absolute;
    right: 0;
    bottom: 0;
    width: 480px;
    height: 500px;
    
    ${props => props.iconStyle && css`${props.iconStyle}`}
`;

export const BackgroundLayout = ({
  children,
  innerStyle,
  iconStyle,
  transparent,
  showIcon = true,
}) => {
  return (
    <div className={'background-layout'}>
      <InnerContainer innerStyle={innerStyle}>{children}</InnerContainer>
      {showIcon && (
        <Icon
          iconStyle={iconStyle}
          src={require('./../../assets/images/siris_background_icon_50.png')}
        />
      )}
    </div>
  );
};
