import React, {useEffect, useState} from 'react';
import {GroupRenderer} from './group-render';
import questionnaireService from './../../services/QuestionnaireService';
import {useTranslation} from 'react-i18next';
import {groupBy} from 'lodash';

RegExp.quote = function (str) {
    return str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
};

export const QuestionnaireGroup = ({
                                       questions,
                                       values,
                                       setFieldValue,
                                       errors,
                                       validateField,
                                       layouts,
                                   }) => {
    const {t} = useTranslation();
    const [timeout, setT] = useState(null);

    useEffect(() => {
        if (questions) {
            questions.forEach(question => {
                if (question.default) {
                    if (!values[question.groupKey || question.key]) {
                        setFieldValue(question.groupKey || question.key, question.default);
                    }
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeAction = (targets, cb) => {
        const updateKeys = new Set();
        for (const target of targets) {
            let targetKey = target.replace(/\$/, '');
            const haveArrayValue = targetKey.match(/(\..*)/);

            if (haveArrayValue?.length) {
                const arrayValue = haveArrayValue[1].replace('.', '');
                targetKey = targetKey.replace(/(\..*)/, '');
                values[targetKey] = [...(values[targetKey] || [])].filter(value => {
                    return `${value}` !== `${arrayValue}`;
                });
                updateKeys.add(targetKey);
            } else {
                values[targetKey] = null;
                updateKeys.add(targetKey);
            }
        }

        if (updateKeys) {
            cb(updateKeys);
        }
    };

    const setValues = updateKeys => {
        setTimeout(() => {
            for (const key of updateKeys) {
                setFieldValue(key, values[key]);
            }
        }, 50);
    };

    const setValue = (target, cb) => {
        const targets = target.match(
            /(\$[a-zA-Z0-9_]+).+?=[\s+]([a-z0-9A-Z_:.['\]]+)/g,
        );
        if (targets?.length) {
            for (const t of targets) {
                const key = t.match(/\$([a-zA-Z0-9_]+)/).pop();
                let value = t.match(/=[\s+]([a-z0-9A-Z_:.['\]]+)/).pop();

                if (value[0] === '[') {
                    // eslint-disable-next-line no-eval
                    values[key] = eval(value);
                    cb([key]);
                } else {
                    if (value[0] === "'" && value[value.length - 1] === "'") {
                        value = value.substring(1, value.length - 1);
                    }

                    // set value
                    values[key] = value;
                    cb([key]);
                }
            }
        }
    };

    const setValueByCalculation = (target, cb) => {
        const variable = target.match(/^#([a-zA-Z0-9_]+).*?=/);
        if (variable?.length === 2) {
            target = target.replace(variable[0], '');
        } else {
            return;
        }

        const targetValues = target.match(/(\$[a-zA-Z0-9_]+)/g);
        if (targetValues?.length > 0) {
            for (const targetValue of targetValues) {
                const [replaceKey, value] =
                    questionnaireService.replacePlaceholderWithValue(targetValue, values);
                target = target.replace(replaceKey, value);
            }

            try {
                values[variable[1]] = (function () {
                    this.calculateFrom = calculateFrom;

                    // eslint-disable-next-line no-eval
                    return eval(target);
                })();
                cb([variable[1]]);
            } catch (e) {
                console.log('a', e);
            }
        }
    };

    const calculateFrom = (joint, key, value) => {
        if (key === 'NO_PREVSURG') {
            if (value === 'null' || !value) {
                return '1';
            }

            const [jointKey, jointType] = joint.split('_');
            let numberOfOptions = value.length;
            const question = questionnaireService.findQuestion(
                t(`spine:joints.${jointKey}.${jointType}`, {returnObjects: true}),
                key,
            );
            if (!question) {
                return;
            }

            switch (numberOfOptions) {
                case 1:
                case 2:
                    return value.indexOf('1') > -1 ? '1' : '2';
                case 3:
                case 4:
                    return '3';
                default:
                    return numberOfOptions >= 5 ? '4' : undefined;
            }
        }
    };

    const setDefaults = () => {
        const questionsWithDefaults = questions.filter(
            question => question.default,
        );
        if (questionsWithDefaults.length > 0) {
            for (const question of questionsWithDefaults) {
                if (!values[question.key]) {
                    values[question.key] = question.default;
                }
            }
        }
    };

    const onSetFieldValue = (key, value) => {
        // set default values if missing
        setDefaults();

        // handle actions
        const question = questions.find(q => q.key === key);
        if (question && question?.actions?.length) {
            const actions = question.actions;

            for (const action of actions) {
                if (questionnaireService.evaluateQuestion(action.rule, {
                    ...values,
                    [key]: value
                })) {
                    switch (action.action) {
                        case 'remove':
                            removeAction(action.targets, setValues);
                            break;
                        case 'set':
                            setValue(action.target, setValues);
                            break;
                        case 'calculate':
                            setValueByCalculation(action.target, setValues);
                            break;
                        default:
                            console.error('Action is unknown', action.action);
                    }
                }
            }
        }

        setFieldValue(key, value);
        validate(key);
    };

    const validate = key => {
        if (timeout) {
            clearTimeout(timeout);
        }
        setT(
            setTimeout(() => {
                try {
                    validateField(key);
                } catch (e) {
                }
            }, 100),
        );
    };

    const getQuestions = () => {
        const groupedBy = groupBy(
            questionnaireService
                .prepareQuestions(1, questions, values)
                ?.filter(question => question?.type !== 'hidden'),
            g => g.groupedBy,
        );

        return Object.keys(groupedBy).map(gKey => {
            return {
                group: groupedBy[gKey][0].group,
                groupedBy: groupedBy[gKey][0].groupedBy,
                key: groupedBy[gKey][0].key,
                type: groupedBy[gKey][0].type,
                options: groupedBy[gKey],
            };
        });
    };

    return (
        <div
            className={'questionnaire-form-group'}
            // todo review
            // style={[
            //   styles.formGroup,
            //   layouts && layouts[questions[0].group]
            //     ? layouts[questions[0].group]
            //     : undefined,
            // ]}
        >
            {getQuestions()?.map(group => {
                return (
                    <GroupRenderer
                        key={group.key}
                        group={group}
                        values={values}
                        setFieldValue={onSetFieldValue}
                        errors={errors}
                    />
                );
            })}
        </div>
    );
};
