import React from "react";
import {getAsset, getStyle} from "@utils/style";
import {ReactComponent as WarningIcon} from '@icons/warning.svg';
import {useTranslation} from "react-i18next";

export const LinkStatus = (props) => {
    const {t} = useTranslation();

    return (
        <div className="link-status" style={{borderColor: getStyle('ERROR_COLOR')}}>
            <div className="link-status-logo">
                <img src={getAsset('image', getStyle('LOGO', true))} alt=""/>
            </div>

            <div className="link-icon-container">
                <WarningIcon style={{color: getStyle('ERROR_COLOR')}} />
                {props.type === 'error' && (
                    <strong>
                        {t(`common:linkError`)}
                    </strong>
                )}

                {props.type !== 'error' && (
                    <strong>
                        {t(`common:${props.type === 'expired'? 'linkExpired': 'consentDenied'}`)}
                    </strong>
                )}
            </div>

            <div className="link-separator" />
        </div>
    );
}
