import React, {useState} from "react";
import Slider from '@appigram/react-rangeslider';
import '@appigram/react-rangeslider/lib/index.css';
import {DynamicTypography} from "@components/DynamicTypography";

export const Type3 = ({group, g, values, setFieldValue}) => {
    const [value, setValue] = useState(values[group.key] || 0);

    return (
        <div className="type-3-questionnaire">
            <div className="content">
                <DynamicTypography content={g.options[0].description}/>
            </div>
            <div className="content-slider">
                <div className="slider-component">
                    <div className="slider-ticks">
                        {Array.from({length: 11}).map((_, k) => {
                            if (k === 0) {
                                return <span key={`tick_${k}`} className="slider-tick" style={{
                                    top: window.innerWidth < 800 ? undefined : '97%',
                                    right: window.innerWidth < 800? '97%': undefined,
                                }}>0</span>
                            }

                            return <span key={`tick_${k}`} className="slider-tick"
                                         style={{
                                             top: window.innerWidth < 800 ? undefined : `${(10 - k) * 9.6}%`,
                                             right: window.innerWidth < 800? `${(10 - k) * 9.6}%`: undefined,
                                         }}>{k * 10}</span>
                        })}
                    </div>
                    <Slider
                        min={0}
                        max={100}
                        orientation={window.innerWidth < 800 ? 'horizontal' : 'vertical'}
                        tooltip={false}
                        value={value}
                        onChange={setValue}
                        onChangeComplete={() => setFieldValue(group.key, value)}
                    />
                    <div className="slider-tick-helpers">
                        {g.options[0].graph_top && (
                            <DynamicTypography component={'span'} content={g.options[0].graph_top}/>
                        )}
                        {g.options[0].graph_bottom && (
                            <DynamicTypography component={'span'} content={g.options[0].graph_bottom}/>
                        )}
                    </div>
                </div>
                <div className="slider-value">
                    <div>
                        <strong>{value}</strong>
                        <DynamicTypography component={'span'} content={g.options[0].graph_value}/>
                    </div>
                </div>
            </div>
        </div>
    );
}
