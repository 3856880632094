import React from "react";
import {getAsset, getStyle} from "@utils/style";

export const Loading = () => {
    return (
        <div className="loading">
            <div className="loading-brand">
                <img src={getAsset('image', getStyle('LOGO', true))} alt=""/>
                <div className="loadingio-spinner-eclipse-82liglvi7sc">
                    <div className="ldio-x0v962a89vn">
                        <div />
                    </div>
                </div>
            </div>
        </div>
    );
}
