import React from "react";
import styled, {css} from 'styled-components';
import {getStyle} from "@utils/style";
import {DynamicTypography} from "@components/DynamicTypography";

const StyledButton = styled.button`
  font-size: ${window.innerWidth < 800? '14px': getStyle('BUTTON_FONT_SIZE', true)};
  height: ${window.innerWidth < 800? '40px': getStyle('BUTTON_HEIGHT', true)};
  opacity: ${props => (props.isLoading ? 0.8 : 1)};
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  border: 1px solid;
  cursor: pointer;
  padding: 10px 0;
  display: flex;
  outline: none;
  width: 100%;

  ${props => props.type === 'primary' && css`
    background-color: ${getStyle('BUTTON_PRIMARY_BACKGROUND', true)};
    color: ${getStyle('BUTTON_PRIMARY_TEXT_COLOR', true)};
    border-color: ${getStyle('BUTTON_PRIMARY_BORDER', true)};
  `}

  ${props => props.type === 'secondary' && css`
    background-color: ${getStyle('BUTTON_SECONDARY_BACKGROUND', true)};
    color: ${props.icon? getStyle('PRIMARY_COLOR', true): getStyle('BUTTON_SECONDARY_TEXT_COLOR', true)};
    border-color: ${getStyle('BUTTON_SECONDARY_BORDER', true)};
  `}
  
   ${props => props.disabled && css`
    background-color: ${getStyle('BUTTON_DISABLED_BACKGROUND', true)};
    color: ${getStyle('BUTTON_DISABLED_TEXT_COLOR', true)};
    border-color: ${getStyle('BUTTON_DISABLED_BORDER', true)};
  `}
   
   svg {
    margin-right: 5px;
    color: ${getStyle('PRIMARY_COLOR', true)};
   }
`

export const Button = (props) => {
    return (
        <StyledButton icon={props.icon} type={props.type} disabled={props.disabled} onClick={props.onClick} style={props.style}>
            {props.icon && props.icon}
            <DynamicTypography content={props.title} component={'span'} />
        </StyledButton>
    );
}
