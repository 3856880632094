const constants = {
    API: process.env.REACT_APP_API || '',
    TENANT: process.env.REACT_APP_TENANT,
    DEFAULT_LANGUAGE: process.env.DEFAULT_LANGUAGE || 'en',
    STYLES: {
        LHG: {
            LOGO: 'lhg-logo.png',
            LOGO_WHITE: 'lhg-logo-white.png',
            PRIMARY_COLOR: '#37ab49',
            SECONDARY_COLOR: '#2d2828',

            INPUT_FONT_SIZE: '16px',
            INPUT_HEIGHT: '50px',
            INPUT_TEXT_COLOR: '#2C2727',
            INPUT_PLACEHOLDER_COLOR: '#707070',
            INPUT_BORDER_COLOR: '#DFE1EC',

            BUTTON_ICON_SIZE: 18,
            BUTTON_HEIGHT: '50px',
            BUTTON_FONT_SIZE: '16px',

            BUTTON_PRIMARY_BACKGROUND: '#37ab49',
            BUTTON_PRIMARY_BORDER: '#37ab49',
            BUTTON_PRIMARY_TEXT_COLOR: '#ffffff',

            BUTTON_SECONDARY_BACKGROUND: '#ffffff',
            BUTTON_SECONDARY_BORDER: '#DFE1EC',
            BUTTON_SECONDARY_TEXT_COLOR: '#707070',

            BUTTON_DISABLED_BACKGROUND: '#E2E2E2',
            BUTTON_DISABLED_BORDER: '#E2E2E2',
            BUTTON_DISABLED_TEXT_COLOR: '#2C2727',

            FORM_GROUP_TITLE_COLOR: '#37ab49',
            FORM_GROUP_TITLE_FONT_SIZE: '24px',

            TITLE_FONT_SIZE: '24px',
            TITLE_COLOR: '#2C2727',
        },
        COMMON: {
            PAGINATION: {
                BACKGROUND_COLOR: '',

                BUTTON_FONT_SIZE: '',
                BUTTON_FONT_COLOR: '',

                CURRENT_PAGE_COLOR: '',
                CURRENT_PAGE_FONT_SIZE: '',
            },
            SURVEY: {
                DESCRIPTION_FONT_SIZE: '',
                DESCRIPTION_COLOR: '',

                TYPE_1: {
                    BUTTON_MIN_WIDTH: ''
                },
            },

            ERROR_COLOR: '#E7443E',
            SUCCESS_COLOR: '#37AB49',

            CONSENT_DENIED: {
                ICON: '',
                FONT_SIZE: '',
            },

            LINK_EXPIRED: {
                ICON: '',
                FONT_SIZE: '',
            }
        }
    },
    RESOURCE: {
        LHG: {
            TITLE: 'LHG'
        }
    },
    COUNTRIES: [
        ["AF", "AFG"],
        ["AL", "ALB"],
        ["DZ", "DZA"],
        ["AD", "AND"],
        ["AO", "AGO"],
        ["AG", "ATG"],
        ["AR", "ARG"],
        ["AM", "ARM"],
        ["AU", "AUS"],
        ["AT", "AUT"],
        ["AZ", "AZE"],
        ["BS", "BHS"],
        ["BH", "BHR"],
        ["BD", "BGD"],
        ["BB", "BRB"],
        ["BY", "BLR"],
        ["BE", "BEL"],
        ["BZ", "BLZ"],
        ["BJ", "BEN"],
        ["BT", "BTN"],
        ["BO", "BOL"],
        ["BA", "BIH"],
        ["BW", "BWA"],
        ["BR", "BRA"],
        ["BN", "BRN"],
        ["BG", "BGR"],
        ["BF", "BFA"],
        ["BI", "BDI"],
        ["KH", "KHM"],
        ["CM", "CMR"],
        ["CA", "CAN"],
        ["CV", "CPV"],
        ["CF", "CAF"],
        ["TD", "TCD"],
        ["CL", "CHL"],
        ["CN", "CHN"],
        ["CO", "COL"],
        ["KM", "COM"],
        ["CR", "CRI"],
        ["HR", "HRV"],
        ["CU", "CUB"],
        ["CY", "CYP"],
        ["CZ", "CZE"],
        ["CD", "COD"],
        ["DK", "DNK"],
        ["DJ", "DJI"],
        ["DM", "DMA"],
        ["DO", "DOM"],
        ["TL", "TLS"],
        ["EC", "ECU"],
        ["EG", "EGY"],
        ["SV", "SLV"],
        ["GQ", "GNQ"],
        ["ER", "ERI"],
        ["EE", "EST"],
        ["ET", "ETH"],
        ["FJ", "FJI"],
        ["FI", "FIN"],
        ["FR", "FRA"],
        ["GA", "GAB"],
        ["GM", "GMB"],
        ["GE", "GEO"],
        ["DE", "DEU"],
        ["GH", "GHA"],
        ["GR", "GRC"],
        ["GD", "GRD"],
        ["GT", "GTM"],
        ["GN", "GIN"],
        ["GW", "GNB"],
        ["GY", "GUY"],
        ["HT", "HTI"],
        ["HN", "HND"],
        ["HK", "HKG"],
        ["HU", "HUN"],
        ["IS", "ISL"],
        ["IN", "IND"],
        ["ID", "IDN"],
        ["IR", "IRN"],
        ["IQ", "IRQ"],
        ["IE", "IRL"],
        ["IL", "ISR"],
        ["IT", "ITA"],
        ["CI", "CIV"],
        ["JM", "JAM"],
        ["JP", "JPN"],
        ["JO", "JOR"],
        ["KZ", "KAZ"],
        ["KE", "KEN"],
        ["KI", "KIR"],
        ["KW", "KWT"],
        ["KG", "KGZ"],
        ["LA", "LAO"],
        ["LV", "LVA"],
        ["LB", "LBN"],
        ["LS", "LSO"],
        ["LR", "LBR"],
        ["LY", "LBY"],
        ["LI", "LIE"],
        ["LT", "LTU"],
        ["LU", "LUX"],
        ["MK", "MKD"],
        ["MG", "MDG"],
        ["MW", "MWI"],
        ["MY", "MYS"],
        ["MV", "MDV"],
        ["ML", "MLI"],
        ["MT", "MLT"],
        ["MH", "MHL"],
        ["MR", "MRT"],
        ["MU", "MUS"],
        ["MX", "MEX"],
        ["MD", "MDA"],
        ["MC", "MCO"],
        ["MN", "MNG"],
        ["ME", "MNE"],
        ["MA", "MAR"],
        ["MZ", "MOZ"],
        ["MM", "MMR"],
        ["NA", "NAM"],
        ["NR", "NRU"],
        ["NP", "NPL"],
        ["NL", "NLD"],
        ["NZ", "NZL"],
        ["NI", "NIC"],
        ["NE", "NER"],
        ["NG", "NGA"],
        ["KP", "PRK"],
        ["NO", "NOR"],
        ["OM", "OMN"],
        ["PK", "PAK"],
        ["PW", "PLW"],
        ["PS", "PSE"],
        ["PA", "PAN"],
        ["PG", "PNG"],
        ["PY", "PRY"],
        ["PE", "PER"],
        ["PH", "PHL"],
        ["PL", "POL"],
        ["PT", "PRT"],
        ["QA", "QAT"],
        ["CG", "COG"],
        ["RO", "ROU"],
        ["RU", "RUS"],
        ["RW", "RWA"],
        ["KN", "KNA"],
        ["LC", "LCA"],
        ["VC", "VCT"],
        ["WS", "WSM"],
        ["SM", "SMR"],
        ["ST", "STP"],
        ["SA", "SAU"],
        ["SN", "SEN"],
        ["RS", "SRB"],
        ["SC", "SYC"],
        ["SL", "SLE"],
        ["SG", "SGP"],
        ["SK", "SVK"],
        ["SI", "SVN"],
        ["SB", "SLB"],
        ["SO", "SOM"],
        ["ZA", "ZAF"],
        ["KR", "KOR"],
        ["ES", "ESP"],
        ["LK", "LKA"],
        ["SD", "SDN"],
        ["SR", "SUR"],
        ["SZ", "SWZ"],
        ["SE", "SWE"],
        ["CH", "CHE"],
        ["SY", "SYR"],
        ["TW", "TWN"],
        ["TJ", "TJK"],
        ["TZ", "TZA"],
        ["TH", "THA"],
        ["TG", "TGO"],
        ["TO", "TON"],
        ["TT", "TTO"],
        ["TN", "TUN"],
        ["TR", "TUR"],
        ["TM", "TKM"],
        ["TV", "TUV"],
        ["UG", "UGA"],
        ["UA", "UKR"],
        ["AE", "ARE"],
        ["GB", "GBR"],
        ["US", "USA"],
        ["UY", "URY"],
        ["UZ", "UZB"],
        ["VU", "VUT"],
        ["VA", "VAT"],
        ["VE", "VEN"],
        ["VN", "VNM"],
        ["YE", "YEM"],
        ["ZM", "ZMB"],
        ["ZW", "ZWE"]
    ],
};

export default constants;
