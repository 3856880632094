import React from 'react';
import Hypher from 'hypher';
import english from 'hyphenation.en-us';
import german from 'hyphenation.de';
import italian from 'hyphenation.it';
import french from 'hyphenation.fr';
import {useTranslation} from 'react-i18next';

const englishHyper = new Hypher(english);
const germanHyper = new Hypher(german);
const italianHyper = new Hypher(italian);
const frenchHyper = new Hypher(french);

const HyphenatedText = ({children, ...props}) => {
  const {i18n} = useTranslation();

  const getHyper = () => {
    switch (i18n.language) {
      case 'en':
        return englishHyper;
      case 'de':
        return germanHyper;
      case 'it':
        return italianHyper;
      case 'fr':
        return frenchHyper;
      default:
        return englishHyper;
    }
  };

  return <p {...props}>{getHyper().hyphenateText(children)}</p>;
};

export default HyphenatedText;
