import React from 'react';
import {Radio} from '../form/radio';
import {CheckBox} from '../form/checkbox';
import _ from 'lodash';
import questionnaireService from './../../services/QuestionnaireService';
import {NumberInput} from '../form/number-input';
import {Type1} from './eq/type-1';
import {Type2} from './eq/type-2';
import {Type3} from './eq/type-3';
import {Flow1} from './flow-1';
import styled, {css} from 'styled-components';

const OptionContainer = styled.div`
  border-top: 1px #000 solid;
  
  ${props => props.isFirst && css`
    border-top: none;
  `}
`;

const LayoutContainer = styled.div`
  background-color: #fff;
  border: 1px #000 solid;
  margin-bottom: 20px;
  border-radius: 6px;
  
  ${props => props.isError && css`
    border-color: #E41E13;
  `}
`;

const GroupTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
  font-size: 12px;
  
  ${props => props.isError && css`
    border-color: #E41E13;
  `}
  
  ${props => props.titleStyle && css`${props.titleStyle}`}
`;

export const GroupRenderer = ({group, values, setFieldValue, errors}) => {
  const toggleCheckbox = (checkboxValues, value) => {
    if (checkboxValues?.indexOf(value) > -1) {
      return (
        checkboxValues &&
        checkboxValues.filter &&
        checkboxValues.filter(val => val !== value)
      );
    }

    return [...(checkboxValues || []), value];
  };

  const renderItem = question => {
    if ((question?.type || group?.type) === 'radio') {
      return (
        <Radio
          inputKey={question.key}
          value={values[group.key]}
          label={question.label}
          onChange={value => setFieldValue(group.key, value)}
          disabled={
            question?.disabled?.length > 0
              ? questionnaireService.evaluateQuestionWithToggle(
                  question.disabled,
                  values,
                  group.key,
                  question.key,
                  setFieldValue,
                )
              : undefined
          }
        />
      );
    } else if ((question?.type || group?.type) === 'checkbox') {
      return (
        <CheckBox
          active={values[group.key]?.indexOf(question.key) > -1}
          label={question.label}
          disabled={
            question?.disabled?.length > 0
              ? questionnaireService.evaluateQuestionWithToggle(
                  question.disabled,
                  values,
                  group.key,
                  question.key,
                  setFieldValue,
                )
              : undefined
          }
          onPress={() =>
            setFieldValue(
              group.key,
              toggleCheckbox(values[group.key], question.key),
            )
          }
        />
      );
    } else if ((question?.type || group?.type) === 'text') {
      return (
        <pArea
          placeholder={question.label}
          value={values[question.questionKey || group.key] || undefined}
          standalone={question.standalone}
          onChange={value =>
            setFieldValue(question.questionKey || group.key, value)
          }
          error={!!errors[question.questionKey || group.key]}
          style={
            !question?.append
              ? {borderWidth: 0, borderColor: 'transparent', width: '99%'}
              : undefined
          }
        />
      );
    } else if ((question?.type || group?.type) === 'number') {
      return (
        <NumberInput
          placeholder={question.label}
          value={values[question.questionKey || group.key] || undefined}
          standalone={question.standalone}
          onChange={value =>
            setFieldValue(question.questionKey || group.key, value)
          }
          error={!!errors[question.questionKey || group.key]}
          style={
            !question?.append
              ? {borderWidth: 0, borderColor: 'transparent', width: '99%'}
              : undefined
          }
        />
      );
    } else if ((question?.type || group?.type) === 'hidden') {
      return null;
    }

    console.log('Unknown type', question.type || group.type);
    return null;
  };

  const renderLayout = (options, containerStyle) => {
    return (
      <LayoutContainer isError={errors[group.key]}>
        {options.map((question, index) => {
            return (
              <OptionContainer
                key={`${group.key}_${index}`}
                isFirst={index === 0}>
                {renderItem(question)}
              </OptionContainer>
            );
          })}
      </LayoutContainer>
    );
  };

  const evaluateAppends = appends => {
    if (appends?.length > 0) {
      const validAppends = questionnaireService.prepareQuestions(
        2,
        appends,
        values,
      );

      if (validAppends?.length) {
        return validAppends.map(append => renderAppend(append.option));
      }
    }

    return null;
  };

  const renderAppend = question => {
    return (
      <div key={question.questionKey} style={styles.appends}>
        {renderItem({...question, append: true})}
      </div>
    );
  };

  const renderInfoButton = form => {
    return (
      <button onClick={() => {
        // todo review
        // navigate('InfoModal', {form})
      }}>
        <img
          src={require('./../../assets/icons/info_question_icon.png')}
          className={'infoQuestionIcon'}
          alt={'More Info'}
        />
      </button>
    );
  };

  const evaluateLayout = () => {
    const groupOptionsClone = questionnaireService.prepareQuestions(
      group.key,
      _.cloneDeep(group.options),
      values,
    );
    if (group?.layout?.columns) {
      let bottomOptions = [];
      let topOptions = [];
      if (group?.layout?.bottom) {
        for (
          let bottomIndex = 0;
          bottomIndex < group?.layout?.bottom;
          bottomIndex++
        ) {
          bottomOptions.push(groupOptionsClone.pop());
        }
      }

      if (group?.layout?.top) {
        for (let topIndex = 0; topIndex < group?.layout?.top; topIndex++) {
          topOptions.push(groupOptionsClone.shift());
        }
      }

      const optionChunk = _.chunk(
        groupOptionsClone,
        Math.ceil(groupOptionsClone.length / group.layout.columns),
      );

      return (
        <>
          {topOptions?.length > 0 && (
            <div>{renderLayout(topOptions, styles.topOptions)}</div>
          )}
          <div
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              marginBottom: group.layout.marginBottom || undefined,
            }}>
            {optionChunk.map((chunk, index) => {
              return (
                <div
                  key={`${group.key}_chunk_${index}`}
                  style={{
                    width: `${
                      100 / optionChunk.length - 0.25 * optionChunk.length
                    }%`,
                  }}>
                  {renderLayout(chunk)}
                  {optionChunk.length - 1 === index &&
                    bottomOptions?.length < 1 &&
                    evaluateAppends(group?.appends)}
                </div>
              );
            })}
          </div>
          {bottomOptions?.length > 0 && (
            <div>
              {renderLayout(bottomOptions.reverse(), styles.bottomOptions)}
              {evaluateAppends(group?.appends)}
            </div>
          )}
        </>
      );
    }

    if (group?.layout?.type === 'split') {
      const grouped = groupOptionsClone.splice(0, group?.layout?.count);
      return (
        <div>
          {renderLayout(grouped)}
          <p style={styles.groupSeparatorTitle}>{group?.layout?.title}</p>
          {renderLayout(groupOptionsClone)}
          {evaluateAppends(group.appends)}
        </div>
      );
    }

    return (
      <div>
        {renderLayout(groupOptionsClone, group.style)}
        {evaluateAppends(group.appends)}
      </div>
    );
  };

  const renderSeparator = g => {
    return (
      <div style={styles.radioGroupSeparator}>
        <p style={styles.radioGroupSeparatorText}>{g.options[0].title}</p>
      </div>
    );
  };

  const renderEQ = g => {
    switch (g.type) {
      case 'eq_type_1':
        return (
          <Type1
            g={g}
            group={group}
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case 'eq_type_2':
        return (
          <Type2
            g={g}
            group={group}
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      case 'eq_type_3':
        return (
          <Type3
            g={g}
            group={group}
            values={values}
            setFieldValue={setFieldValue}
          />
        );
      default:
        console.log('unknown type', g.type);
        return null;
    }
  };

  const renderRadioGroup = g => {
    return (
      <div style={styles.radioGroupContainer}>
        <div style={styles.radioGroupLabels}>
          {g.options.map(option => (
            <div style={styles.radioGroupRow} key={`label_${option.key}`}>
              <p key={option.key} style={styles.radioGroupTitle}>
                {option.title}
              </p>
            </div>
          ))}
        </div>
        <div style={styles.radioGroupButtons}>
          <div style={[styles.radioGroupRow, styles.radioGroupHeader]}>
            <div style={{flexDirection: 'row'}}>
              {g.options[0].options.map(answer => (
                <div
                  key={`label_${g.group}_${g.groupBy}_${answer.key}`}
                  style={styles.radioGroupBtnArea}>
                  <p style={styles.radioGroupHeadLabel}>{answer.label}</p>
                </div>
              ))}
            </div>
          </div>
          {g.options.map((question, index) => {
            return (
              <div
                style={styles.radioGroupRow}
                key={g.group + '_' + g.groupedBy + '_' + index.toString()}>
                <div style={{flexDirection: 'row'}}>
                  {g.options[0].options.map(answer => (
                    <div
                      style={styles.radioGroupBtnArea}
                      key={question.key + '_' + answer.key}>
                      <Radio
                        inputKey={answer.key}
                        value={values[question.key]}
                        onChange={value => setFieldValue(question.key, value)}
                        style={{paddingRight: 8, justifyContent: 'center'}}
                        radioStyle={{
                          marginRight: 0,
                          ...(errors[question.key]
                            ? {borderColor: 'red'} // todo review
                            : undefined),
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  if (group.type === 'grouped') {
    return renderRadioGroup(group);
  } else if (group.type.startsWith('eq_type_')) {
    return renderEQ(group);
  } else if (group.type.startsWith('flow_1')) {
    return (
      <Flow1
        group={group}
        values={values}
        setFieldValue={setFieldValue}
        errors={errors}
      />
    );
  } else if (group.type === 'desc_separator') {
    return renderSeparator(group);
  } else {
    return (
      <div
        style={{
          width: group?.layout?.width || '24%',
          marginHorizontal: '0.5%',
        }}>
        <div style={{flexDirection: 'row', alignItems: 'flex-start'}}>
          <GroupTitle
              isError={errors[group.key]}
              titleStyle={group?.titleStyle}>
            {group.title}
          </GroupTitle>
          {group.info &&
            Boolean(group.info) === true &&
            renderInfoButton(group)}
        </div>
        {evaluateLayout()}
      </div>
    );
  }
};

const styles = {};
