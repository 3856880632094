import {takeLatest, put} from 'redux-saga/effects';
import produce from 'immer';
import createAction from '@utils/action-creator';
import axios from '@utils/axios';
import {GENERATE_LINK_SUCCESS} from "@store/sagas/patient";
import {select} from "@redux-saga/core/effects";
import {push} from "react-router-redux";
import i18n from '@app/i18n';
import questionnaireService from "@app/services/QuestionnaireService";

/**
 * CONSTANTS
 */
export const REQUEST = '@app/consent/REQUEST';
export const SET_LINK = '@app/consent/SET_LINK'
export const SET_LOADING = '@app/consent/SET_LOADING';
export const SET_STATUS = '@app/consent/SET_STATUS';
export const SET_ERROR = '@app/consent/SET_ERROR';
export const SUBMIT_ANSWERS = '@app/consent/SUBMIT_ANSWERS';
export const SET_LANGUAGE = '@app/consent/SET_LANGUAGE';
export const SAVE_ANSWERS = '@app/consent/SAVE_ANSWERS';
export const ACTIVE_TAB = '@app/consent/ACTIVE_TAB';

const _state = {
    loading: true,
    status: null, // denied
    patient: null,
    task: null,
    error: null,
    form: {},
    language: 'de'
};

const reducer = (state = _state, action) => (
    produce(state, draft => {
        switch (action.type) {
            case SET_LOADING:
                draft.loading = action.payload;
                break;
            case SET_LINK:
                draft.patient = action.payload.patient;
                draft.task = action.payload.task;
                draft.form = action.payload.form;
                break;
            case SET_LANGUAGE:
                draft.language = action.payload;
                break;
            case SAVE_ANSWERS:
                draft.form.answers = Object.assign(draft.form.answers || {}, action.payload);
                break;
            case ACTIVE_TAB:
                draft.form.activeTab = action.payload;
                break;
            case SET_STATUS:
                draft.status = action.payload;
                break;
            case SET_ERROR:
                draft.error = action.payload;
                break;
            default:
                break;
        }
    })
);
export default reducer;

export const actions = {
    requestLink: (payload) => createAction(REQUEST, {payload}),
    setLink: (payload) => createAction(SET_LINK, {payload}),
    setLoading: (payload) => createAction(SET_LOADING, {payload}),
    setStatus: (payload) => createAction(SET_STATUS, {payload}),
    setError: (payload) => createAction(SET_ERROR, {payload}),
    submit: (payload) => createAction(SUBMIT_ANSWERS, {payload}),
    setLanguage: (payload) => createAction(SET_LANGUAGE, {payload}),
    saveAnswers: (payload) => createAction(SAVE_ANSWERS, {payload}),
    setActiveTab: (payload) => createAction(ACTIVE_TAB, {payload}),
};

export const sagas = {
    * setLink(action) {
        const {
            payload
        } = action;

        yield put(actions.setLink(payload));
        yield put(actions.setLoading(false));
        yield put(actions.setLanguage(payload.language || ''));
    },
    * request(action) {
        const {
            payload: { token, promId }
        } = action;

        yield put(createAction(SET_LOADING, { payload: true }));
        try {
            const response = yield (axios.get(`/patients/${token}`));
            const result = response.data;

            const joint = result.task.patients[0].parameters.find(param => param.name === 'JOINT');
            const side = result.task.patients[0].parameters.find(param => param.name === 'SIDE');
            const op = result.task.patients[0].parameters.find(param => param.name === 'PRE_POST_OPERATION');
            const implementation_date = result.task.patients[0].parameters.find(param => param.name === 'OPERATION_DATE');
            const taskCaseId = result.task.patients[0].parameters.find(param => param.name === 'TASK_CASE_ID');
            const formTaskPatientId = result.task.patients[0].id;
            let proms = result.task.proms;
            if (promId) {
               proms = proms.filter(prom => parseInt(prom.id, 10) === parseInt(promId, 10));
            }

            let mappedJoint = null;
            switch (joint.value) {
                case '1':
                    mappedJoint = 'knee';
                    break;
                case '2':
                    mappedJoint = 'shoulder';
                    break;
                case '3':
                    mappedJoint = 'hip';
                    break;
                default:
                    alert(i18n.t('common:login.errors.unknown_joint'));
                    return;
            }

            let mappedSide = null;
            switch (side.value) {
                case '0':
                    mappedSide = 'right';
                    break;
                case '1':
                    mappedSide = 'left';
                    break;
                default:
                    alert(i18n.t('common:login.errors.unknown_side'));
                    return;
            }

            // set mapped joint to patient
            result.patient.joint = mappedJoint;
            result.patient.op = op.value === "0" ? "pre": "post";
            if (implementation_date) {
                result.patient.implementation_date = implementation_date.value;
            }
            if (taskCaseId) {
                result.patient.taskCaseId = taskCaseId.value;
            }
            if (formTaskPatientId) {
                result.patient.formTaskPatientId = formTaskPatientId;
            }

            const tabs = proms.map(prom => {
                const promName = prom.promName;
                let questionnaire = '';
                if (promName.includes('KOOS')) {
                    questionnaire = 'koss';
                } else if (promName.includes('EQ5D5L')) {
                    questionnaire = 'eq';
                } else if (promName.includes('HOOS')) {
                    questionnaire = 'hoos';
                } else if (promName.includes('SHOULDER')) {
                    questionnaire = 'oxsh';
                }

                let jointKey = '';
                if (questionnaire === 'eq' || questionnaire === 'oxsh') {
                    jointKey = 'single';
                } else {
                    jointKey = mappedJoint;
                }

                return {
                    key: `${jointKey}_${mappedSide}_x_${questionnaire}`,
                    joint: mappedJoint,
                    side: i18n.t(`common:sides.${mappedSide}`),
                    skipOverview: true,
                    type: questionnaire,
                };
            });

            // // define form from payload
            result.form = {
                activeTab: tabs[0].key,
                tabs,
            };

            yield put(createAction(SET_LINK, { payload: result }));
            yield put(actions.setLanguage(result.task.defaultLanguage && result.task.defaultLanguage !== '-1' ? result.task.defaultLanguage: 'de'));
        } catch (error) {
            console.error('Failed to pull token/patient info', error);
            yield put(actions.setError('Failed to load provided token'));
        } finally {
            yield put(createAction(SET_LOADING, { payload: false }));
        }
    },
    * submitAnswers() {
        console.log('Submitting answers');
        const form = yield select( (state) => state.app.questionnaire.form );
        const patient = yield select( (state) => state.app.questionnaire.patient );
        yield put(actions.setLoading(true));
        yield put(push(`/questionnaire/submit`));

        const results = {
            success: [],
            failed: [],
        };

        try {
            const forms = [];
            for (const questionKey of Object.keys(form.answers)) {
                if (questionKey === 'overview') {
                    continue;
                }

                const mappedForm = questionnaireService.mapQuestions(
                    patient,
                    questionKey,
                    form.answers[questionKey],
                    i18n.language,
                );

                // Form with side, timestamp and PID
                forms.push({
                    questionKey,
                    patient,
                    form: mappedForm,
                });
            }

            for (const aForm of forms) {
                try {
                    const [success, exception] = yield questionnaireService.processSubmission(aForm);
                    if (success) {
                        results.success.push(aForm);
                    } else {
                        results.failed.push({...aForm, exception});
                    }
                } catch (exception) {
                    results.failed.push({...aForm, exception});
                }
            }

            console.log('total success', results.success.length);
            console.log('total error', results.failed.length);
            yield put(actions.setError(results.failed.length > 0));
        } catch(e) {
            console.error('e', e);
            yield put(actions.setError(true));
        } finally {
            yield put(actions.setLoading(false));
        }
    }
};

export const watcher = function* w() {
    yield takeLatest(GENERATE_LINK_SUCCESS, sagas.setLink);
    yield takeLatest(REQUEST, sagas.request);
    yield takeLatest(SUBMIT_ANSWERS, sagas.submitAnswers);
};
