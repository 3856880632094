import React from 'react';
import HyphenatedText from "@components/HyphenatedText";

export const Radio = ({
  label,
  value,
  inputKey,
  onChange,
  style,
  radioStyle,
  disabled,
  error,
}) => {
  return (
    <button
      style={[styles.container, style, error ? styles.errorState : undefined]}
      disabled={disabled}
      onClick={() => onChange(inputKey)}>
      <div
        style={[
          styles.radio,
          disabled ? styles.disabledRadio : undefined,
          radioStyle,
        ]}>
        {value === inputKey && <div style={styles.radioActive} />}
      </div>
      {label && (
        <HyphenatedText
          style={[
            styles.label,
            value === inputKey ? styles.labelActive : undefined,
            disabled ? styles.labelDisabled : undefined,
          ]}>
          {label}
        </HyphenatedText>
      )}
    </button>
  );
};

const styles = {};
