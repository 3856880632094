import React from "react";
import {getAsset, getStyle} from "@utils/style";
import {DynamicTypography} from "@components/DynamicTypography";

export const Header = (props) => {
    return (
        <div className={`questionnaire-header ${props.className || ''}`}>
            <div className="logo">
                <img src={getAsset('image', getStyle('LOGO', true))} alt=""/>
            </div>
            <div className="title">
                <h1 style={{color: getStyle('TITLE_COLOR', true)}}>
                    {props.title} {props.pii && (
                        <>
                        {props.pii.firstName} {props.pii.lastName}
                        </>
                    )}
                </h1>
                {props.subTitle && (
                    <DynamicTypography content={props.subTitle} />
                )}
            </div>
            <div>
                {props.questionnaireType}
            </div>
        </div>
    );
}
