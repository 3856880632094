import React from 'react';
import {useTranslation} from "react-i18next";
import {DynamicTypography} from "@components/DynamicTypography";

export const NotFound = () => {
    const {t} = useTranslation();

    return (
        <div className="container">
            <div className="not-found-container">
                <DynamicTypography content={t('common:notFound.title')} component={'h1'} />
                <DynamicTypography content={t('common:notFound.desc')} />
            </div>
        </div>
    )
}
