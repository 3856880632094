import React, {Component} from "react";
import {getAsset, getStyle} from "@utils/style";
import {ReactComponent as CheckIcon} from "@icons/check.svg";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Loading} from "@components/loading";
import {ReactComponent as WarningIcon} from "@icons/warning.svg";

class Success extends Component {

    render() {
        if (this.props.loading) {
            return <Loading/>
        }

        return (
            <div className="link-status"
                 style={{borderColor: getStyle(this.props.error ? 'ERROR_COLOR' : 'SUCCESS_COLOR')}}>
                <div className="link-status-logo">
                    <img src={getAsset('image', getStyle('LOGO', true))} alt=""/>
                </div>

                <div className="link-icon-container">
                    {!this.props.error && (
                        <>
                            <span style={{backgroundColor: getStyle('SUCCESS_COLOR')}}>
                                <CheckIcon style={{color: '#ffffff'}}/>
                            </span>
                            <strong>
                                {this.props.t('common:questionnaire.success')}
                            </strong>
                        </>
                    )}
                    {this.props.error && (
                        <>
                            <WarningIcon style={{color: getStyle('ERROR_COLOR')}}/>
                            <strong>
                                {this.props.t(`common:submissionFailed`)}
                            </strong>
                        </>
                    )}
                </div>

                <div className="link-separator"/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.app.questionnaire.loading,
    error: state.app.questionnaire.error
});
export default withTranslation()(connect(mapStateToProps, null)(Success));
