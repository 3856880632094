import constants from '@constants/index';
import _ from 'lodash';

export const getStyle = (path, tenant = false) => {
    const STYLE = tenant? constants.STYLES[constants.TENANT]: constants.STYLES.COMMON;
    return _.get(STYLE, path);
}

export const getAsset = (type, path) => {
    if (type === 'image') {
        return require(`../assets/images/${path}`);
    }

    return path;
}

export const getResource = (path) => {
    return _.get(constants.RESOURCE[constants.TENANT], path);
}
