import React from "react";
import {Button} from "@components/form/button";
import {getStyle} from "@utils/style";
import {DynamicTypography} from "@components/DynamicTypography";

export const Type2 = ({group, g, values, setFieldValue}) => {
    const isTrue = k => {
        return values[group.key] !== undefined && values[group.key] === k;
    };

    return (
        <div className="type-2-questionnaire">
            <div className="form-group">
                {Array.from({length: 11}).map((_, k) => {
                    return (
                        <Button title={k.toString()} type={isTrue(k) ? 'primary' : 'secondary'}
                                key={k.toString()} onClick={() => setFieldValue(group.key, k)} style={{
                            height: (k * 10) + 60,
                        }}/>
                    )
                })}
            </div>
            <div className="form-group-limits" style={{borderColor: getStyle('BUTTON_SECONDARY_BORDER', true)}}>
                <span>
                    <DynamicTypography
                        content={g.options[0].graph_bottom}
                    />
                </span>
                <span>
                  <DynamicTypography
                      content={g.options[0].graph_top}
                  />
                </span>
            </div>
        </div>
    );
}
