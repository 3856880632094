import {cloneDeep, isEqual} from 'lodash';
import moment from 'moment';
import axios from '@utils/axios';
import i18n from '@app/i18n';

RegExp.quote = function (str) {
    return str.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
};

class QuestionnaireService {
    evaluateQuestionWithToggle = (
        condition,
        values,
        groupKey,
        optionKey,
        setFieldValue,
    ) => {
        const copiedValues = cloneDeep(values);

        const evaluation = this.evaluateQuestion(condition, copiedValues);
        if (evaluation) {
            if (typeof copiedValues[groupKey] === 'object') {
                copiedValues[groupKey] = [...(copiedValues[groupKey] || [])].filter(
                    key => {
                        return `${key}` !== `${optionKey}`;
                    },
                );
            } else {
                copiedValues[groupKey] =
                    copiedValues[groupKey] !== optionKey
                        ? copiedValues[groupKey]
                        : undefined;
            }
        }

        if (!isEqual(copiedValues[groupKey], values[groupKey])) {
            setFieldValue(groupKey, copiedValues[groupKey]);
        }

        return evaluation;
    };

    evaluateQuestion = (condition, values) => {
        if (!condition) {
            return false;
        }

        if (typeof condition === 'boolean') {
            return condition;
        }

        const conditions = condition.match(/(\$[a-zA-Z0-9_]+)/g);
        if (conditions?.length > 0) {
            for (const c of conditions) {
                const [replaceKey, value] = this.replacePlaceholderWithValue(c, values);
                condition = condition.replace(replaceKey, value);
            }
        }

        if (condition) {
            return condition === "0 == 0";
        }

        // disabled due TrustedScript XSS reported issue
        // try {
        //     // eslint-disable-next-line no-eval
        //     return eval(condition);
        // } catch (_error) {
        //     return false;
        // }
        return false;
    };

    replacePlaceholderWithValue = (c, values) => {
        let value = values[c.replace('$', '')];
        if (!value?.length && typeof value !== 'number') {
            value = undefined;
        }

        if (value && Array.isArray(value) && value?.length > 0) {
            value = `["${value.join('","')}"]`;
        } else if (typeof value === 'string') {
            value = `'${value}'`;
        } else if (typeof value === 'undefined') {
            value = "'null'";
        }
        const replaceKey = new RegExp(RegExp.quote(c), 'g');
        return [replaceKey, value];
    };

    /**
     *
     * @param questionKey
     * -1 does not emit check rules back
     * 1 checks for questions.key in group level
     * if evaluation is false will emit back result
     * @param questions group of questions or group options
     * @param values
     * @returns array of available questions
     */
    prepareQuestions = (questionKey, questions, values) => {
        return questions?.filter(question => {
            if (question?.condition?.length || question?.rule?.length) {
                const evaluation = this.evaluateQuestion(
                    question.condition || question.rule,
                    values,
                );

                if (!evaluation && questionKey === 1) {
                    values[question.key] = undefined;
                }

                if (!evaluation && questionKey === 2 && question?.option?.questionKey) {
                    values[question.option.questionKey] = undefined;
                }

                if (
                    !evaluation &&
                    question.questionKey &&
                    typeof questionKey === 'string'
                ) {
                    values[question.questionKey] = undefined;
                }

                return evaluation;
            }

            return true;
        });
    };

    findQuestion = (questions, questionKey) => {
        return questions.find(q => q.key === questionKey);
    };

    getAnswerByValue = (questions, answers, questionKey) => {
        const question = this.findQuestion(questions, questionKey);
        if (question?.options) {
            return (
                question.options
                    .filter(option => {
                        const answer = answers[questionKey];
                        if (question.type === 'checkbox') {
                            return answer.indexOf(option.key) > -1;
                        }
                        return option.key === answer && answer[0];
                    })
                    ?.map(option => option?.label) || []
            );
        }

        return '';
    };

    mapQuestions = (patient, key, answers, language) => {
        const [m, jointSide, , questionnaireType] = key.split('_');
        const jointName = patient.joint;
        const questions = i18n.t(`${questionnaireType}:joints.${m}`, {
            returnObjects: true,
        });

        const formName = answers.FORM_NAME.split(':');

        const formAnswers = [];
        if (questionnaireType === 'koss') {
            formAnswers.push(
                ...[
                    {
                        questionname: 'IV_LOCATION_QUESTION',
                        values: [jointSide === 'right' ? '1' : '2'],
                    },
                    {
                        questionname: 'IV_DATE_QUESTION',
                        values: [moment().format('DD.MM.YYYY')],
                    },
                    {
                        questionname: 'INTERVAL',
                        values: [patient.op && patient.op === 'pre' ? '1' : '2'],
                    },
                    ...(patient.op && patient.op === 'post'
                        ? [
                            {
                                questionname: 'SURGERY_DATE',
                                values: [`${patient.implementation_date}`],
                            },
                        ]
                        : []),
                ],
            );
        } else if (questionnaireType === 'hoos') {
            formAnswers.push(
                ...[
                    {
                        questionname: 'IV_LOCATION_QUESTION',
                        values: [jointSide === 'right' ? '1' : '2'],
                    },
                    {
                        questionname: 'IV_DATE_QUESTION',
                        values: [moment().format('DD.MM.YYYY')],
                    },
                    {
                        questionname: 'INTERVAL',
                        values: [patient.op === 'post' ? '2' : '1'],
                    },
                    ...(patient.op && patient.op === 'post'
                        ? [
                            {
                                questionname: 'SURGERY_DATE',
                                values: [`${patient.implementation_date}`],
                            },
                        ]
                        : []),
                ],
            );
        } else if (questionnaireType === 'oxsh') {
            formAnswers.push(
                ...[
                    {
                        questionname: 'IV_LOCATION_QUESTION',
                        values: [jointSide === 'right' ? '1' : '2'],
                    },
                    {
                        questionname: 'IV_DATE_QUESTION',
                        values: [moment().format('DD.MM.YYYY')],
                    },
                    {
                        questionname: 'INTERVAL',
                        values: [patient.op === 'post' ? '1' : '0'],
                    },
                    ...(patient.op && patient.op === 'post'
                        ? [
                            {
                                questionname: 'SURGERY_DATE',
                                values: [`${patient.implementation_date}`],
                            },
                        ]
                        : []),
                ],
            );
        } else if (questionnaireType === 'eq') {
            let iv_location = '';
            if (jointName === 'hip') {
                iv_location = 1;
            } else if (jointName === 'knee') {
                iv_location = 2;
            } else if (jointName === 'shoulder') {
                iv_location = 3;
            }

            formAnswers.push(
                ...[
                    {
                        questionname: 'INTERVAL',
                        values: [patient.op === 'post' ? '1' : '0'],
                    },
                    {
                        questionname: 'IV_LOCATION_QUESTION',
                        values: [jointSide === 'left' ? '2' : '1'],
                    },
                    {
                        questionname: 'IV_LOCATION_QUESTION2',
                        values: [iv_location.toString()],
                    },
                    {
                        questionname: 'IV_DATE_QUESTION',
                        values: [moment().format('DD.MM.YYYY')],
                    },
                    ...(patient.implementation_date
                        ? [
                            {
                                questionname: 'SURGERY_DATE',
                                values: [`${patient.implementation_date}`],
                            },
                        ]
                        : []),
                ],
            );
        }

        Object.keys(answers).forEach(questionKey => {
            const foundQuestion = questions.find(
                question => question.key === questionKey,
            );

            // if the question is not found at all, skip
            if (!foundQuestion) {
                return;
            }

            if (
                foundQuestion?.ignore &&
                this.evaluateQuestion(foundQuestion.ignore, answers)
            ) {
                // prevent mapping ignored questions
                return;
            }

            if (foundQuestion?.key === 'FORM_NAME') {
                // prevent formname as answer
                return;
            }

            if (
                foundQuestion?.condition &&
                !this.evaluateQuestion(foundQuestion.condition, answers)
            ) {
                // prevent mapping not qualified questions
                return;
            }

            let value = answers[questionKey];
            if (!value || value === 'null') {
                return;
            }

            if (!Array.isArray(value)) {
                value = [`${value}`];
            }

            formAnswers.push({
                questionname: foundQuestion?.toKey || questionKey,
                values: value,
            });
        });

        const form = {
            name: formName[0],
            version: formName[1],
            formcreatedby: patient.formcreatedby,
            formlanguage: language,
            deptID: patient.departmentId,
            answers: formAnswers,
        };

        if (patient.formTaskPatientId) {
            form.formTaskPatientId = patient.formTaskPatientId;
        }
        if (patient.taskCaseId) {
            form.taskCaseId = patient.taskCaseId;
        }

        return this.mapPatient(patient, form, language);
    };

    mapPatient = (patient, form, language) => {
        return {
            mrn: patient.mrn,
            dob: patient.dateOfBirth,
            gender: patient.gender === 'FEMALE' ? 'f' : 'm',
            language: language,
            forms: [form],
        };
    };

    processSubmission = async (submission) => {
        try {
            await axios.post(`/patients/submit/${submission.form.mrn}`, submission.form.forms[0]);
            return [true, null];
        } catch (exception) {
            console.log('failed to submit', exception);
            return [false, exception?.errors];
        }
    };
}

export default new QuestionnaireService();
