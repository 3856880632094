import React from 'react';

export const DynamicTypography = ({content, className, withoutSpace, component = 'p'}) => {
  const RenderComponent = `${component}`;

  return (
    <RenderComponent className={`dynamic-typo ${className}`}>
      {content.split(/(\*.*?\*)/g).map((k, kk) => {
        if (k.length === 0) {
          return '';
        }

        if (String(k)[0] === '*' && String(k)[k.length - 1] === '*') {
          return (
            <strong key={kk}>
              {k.replace(/\*/g, '').trim()}
            </strong>
          );
        } else {
          return k + (!withoutSpace ? ' ' : '');
        }
      })}
    </RenderComponent>
  );
};
