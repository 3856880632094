import React from "react";
import {useTranslation} from 'react-i18next';
import {Button} from "@components/form/button";

export const LangSwitcher = (props) => {
    const {t, i18n} = useTranslation();

    const changeLanguage = async (lang) => {
        if (props.onChange) {
            props.onChange(lang);
        } else {
            await i18n.changeLanguage(lang);
        }
    };

    return (
        <div className="lang-switcher">
            <p>{t('common:chooseLanguage')}</p>

            <div className="lang-options">
                {t('common:languages', {returnObjects: true}).map((lang) => {
                    return (
                        <Button key={lang.value} title={lang.name}
                                type={lang.key.includes(props.active) ? 'primary' : 'secondary'}
                                onClick={() => changeLanguage(lang.value)}/>
                    )
                })}
            </div>
        </div>
    );
}
