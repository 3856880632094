import React, { useEffect, useRef, useState} from 'react';
import {FormLayout} from '@components/layout/form-layout';
import {useTranslation} from 'react-i18next';
import {isEmpty, cloneDeep, groupBy} from 'lodash';
import {QuestionnaireGroup} from '@components/questionnaire/QuestionnaireGroup';
import {Formik} from 'formik';
import * as Yup from 'yup';
import questionnaireService from '../services/QuestionnaireService';
import {DynamicTypography} from "@components/DynamicTypography";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router";
import {actions} from '@store/sagas/questionnaire';

export const Questionnaire = () => {
  const {activeTab, tabs, answers} = useSelector((state) => state.app.questionnaire.form);
  const task = useSelector((state) => state.app.questionnaire.task);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();

  const {t} = useTranslation();
  const [formGroups, setFormGroups] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);
  const [layouts, setLayouts] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setAllPageCount] = useState(0);
  const [changing, setChange] = useState(false);
  const formRef = useRef();

  const getQuestions = () => {
    return questionnaireService.prepareQuestions(
      1,
      formGroups
        ?.map(formGroup =>
          formGroup?.data
            ?.flat() // flatten groups (pages)
            ?.map(option => {
              const {layout, appends, actions, ...props} = option;
              if (appends?.length) {
                return [
                  props,
                  appends.map(append => {
                    return {
                      group: props.group,
                      key: append.option.questionKey,
                      type: append.option.type,
                      title: append.option.label,
                      condition: append.rule,
                    };
                  }),
                ].flat(); // flatten appends into same level with main question
              }

              return props;
            })
            // flatten questions with appends
            ?.flat(),
        )
        ?.flat()
        ?.filter(valid => valid),
      formRef?.current?.values,
    );
  };

  const generateValidation = () => {
    const questions = getQuestions();

    const values = formRef?.current?.values;
    const shape = {};
    if (questions?.length) {
      for (const question of questions) {
        shape[question.key] = Yup.lazy(val => {
          const isArray = Array.isArray(val);
          let validation = isArray ? Yup.array() : Yup.string();
          const availableOptions = question?.options?.filter(
            option =>
              !option.disabled ||
              (option.disabled &&
                !questionnaireService.evaluateQuestion(
                  option.disabled,
                  values,
                )),
          );

          if (
            question?.optional ||
            (question.type !== 'text' && !availableOptions?.length)
          ) {
            return validation.nullable();
          }

          let rule;
          if (isArray) {
            rule = Yup.array().of(Yup.string());
            if (question?.minOptions) {
              rule = rule.min(
                questionnaireService.evaluateQuestion(
                  question.minOptions,
                  values,
                ),
                `${question.title} ${t('common:validation.isMandatory')}`,
              );
            } else {
              rule = rule.min(
                1,
                `${question.title} ${t('common:validation.isMandatory')}`,
              );
            }
          } else {
            rule = Yup.string().required(
              `${question.title} ${t('common:validation.isMandatory')}`,
            );
          }

          if (question?.validationGroup?.length > 0) {
            validation = validation.when(question.validationGroup, {
              is: (...props) => props.filter(propVal => !!propVal).length < 1,
              then: rule,
              otherwise: Yup.string().nullable(),
            });
          } else {
            validation = rule;
          }

          if (question?.type === 'number') {
            rule = Yup.number().required();
            if (question.minValue || question.minValue === 0) {
              rule = rule.min(question.minValue);
            }

            if (question.maxValue || question.maxValue === 0) {
              rule = rule.max(question.maxValue);
            }

            validation = rule;
          }

          return validation;
        });

        if (question?.validationRules?.length > 0) {
          for (const validationRule of question.validationRules) {
            shape[validationRule.then] = Yup.string().when(
              validationRule.when,
              value => {
                if (value === validationRule.includes) {
                  return Yup.string().required();
                }
              },
            );
          }
        }
      }
    }

    return Yup.object().shape(shape);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, []);

  useEffect(() => {
    if (activeTab) {
      const [joint, , type] = activeTab.split('_');
      const questionnaireType = tabs.find(qt => qt.key === activeTab).type;
      setLayouts(
        t(`${questionnaireType}:layouts.${joint}_${type}`, {returnObjects: true}),
      );
      let groups = getFormGroups();
      const localAnswers = getValueswithDefaults(
          (answers && answers[activeTab]) || {},
          groups,
      );
      formRef.current.setValues(localAnswers);
      // remove groups without any question on them
      // might not work for dynamic questionnaires such as QR
      groups = groups.filter(gr => {
        return (
            questionnaireService.prepareQuestions(1, gr.data[0], localAnswers)
                .length > 0
        );
      });
      setFormGroups(groups);
      setAllPageCount(groups.length);
    } else {
      navigate(`/questionnaire/${params.token}`, {replace: true});
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab]);

  const checkForErrors = async (displayAlert = true) => {
    const errors = await formRef?.current.validateForm();
    if (!isEmpty(errors)) {
      const firstError = getQuestions()?.find(
        question => errors[question.key]?.length,
      );
      const sectionIndex = formGroups.findIndex(
        group => group.phaseKey === firstError?.group,
      );

      if (displayAlert) {
        alert(t('common:validation.questionnaire.missingFields'));
        setTimeout(() => {
          setCurrentPage(sectionIndex || 0);
        }, 100);
      }
      return true;
    }

    return false;
  };

  const getValueswithDefaults = (evaluateValues, evaluateGroups) => {
    const values = JSON.parse(JSON.stringify(evaluateValues || formRef?.current?.values));
    const questionsWithDefaults = cloneDeep(evaluateGroups || formGroups)
        .map(formGroup => formGroup.data.flat())
        .flat()
        .filter(question => question.default);
    if (questionsWithDefaults.length > 0) {
      for (const question of questionsWithDefaults) {
        if (!values[question.key] || values[question.key].length < 1) {
          if (
              question.ignore &&
              questionnaireService.evaluateQuestion(question.ignore, values)
          ) {
            delete values[question.key];
          } else {
            values[question.key] = question.default;
          }
        }
      }
    }

    // inject PRE_POST_OPERATION question in ANSWER
    const PRE_POST_OPERATION = task.patients[0].parameters.find(
        p => p.name === 'PRE_POST_OPERATION',
    );
    if (PRE_POST_OPERATION != null) {
      values.PRE_POST_OPERATION = parseInt(PRE_POST_OPERATION.value, 10);
    }

    return values;
  };

  const onNext = async () => {
    if (
      (currentPage < formGroups.length - 1 && !shouldSubmit) ||
      (currentPage < formGroups.length - 1 &&
        shouldSubmit &&
        (await checkForErrors(false)))
    ) {
      setChange(true);
      setTimeout(() => {
        setCurrentPage(currentPage + 1);
        setChange(false);
      }, 650);
    } else {
      if (await checkForErrors()) {
        if (!shouldSubmit) {
          setShouldSubmit(true);
        }
        return;
      }

      const formRoutes = formRouter();
      if (
        formRoutes.find(formRoute => formRoute.key !== 'overview') &&
        formRoutes.map(tab => tab.key).indexOf(activeTab) ===
          formRoutes.length - 1
      ) {
        dispatch(actions.saveAnswers({[activeTab]: getValueswithDefaults()}));
        setTimeout(() => {
          dispatch(actions.submit())
        }, 300);
        return;
      }

      const nextTab =
        formRoutes[formRoutes.map(tab => tab.key).indexOf(activeTab) + 1];
      dispatch(actions.saveAnswers({[activeTab]: getValueswithDefaults()}));
      setCurrentPage(0);
      dispatch(actions.setActiveTab(nextTab.key));
      setTimeout(() => {
        setCurrentPage(0);
      }, 50);
    }
  };

  const onBack = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    } else {
      const formRoutes = formRouter();
      const formIndex = formRoutes.map(tab => tab.key).indexOf(activeTab);
      if (formIndex <= 0) {
        navigate(-1);
      } else {
        const nextActiveTab = formRoutes[formIndex - 1].key;
        setCurrentPage(0);
        dispatch(actions.setActiveTab(nextActiveTab));
        formRef.current.setValues(answers[nextActiveTab] || {});
        setTimeout(() => {
          if ((getFormGroups(nextActiveTab).length || 1) - 1 > 0) {
            setCurrentPage((getFormGroups(nextActiveTab).length || 1) - 1);
          }
        }, 0);
      }
    }
  };

  const formRouter = () => {
    return [
      ...tabs,
      ...(tabs && !tabs[0].skipOverview
        ? [
            {
              key: 'overview',
              title: t('common:overview.title'),
            },
          ]
        : []),
    ];
  };

  const getQuestionaireType = () => {
    return tabs.find(qt => qt.key === activeTab).type;
  };

  const getFormGroups = (sActive) => {
    let localActiveTab = activeTab;
    if (sActive) {
      localActiveTab = sActive;
    }

    const [joint] = localActiveTab.split('_');
    const questionnaireType = getQuestionaireType();
    let questionnaire = t(`${questionnaireType}:joints.${joint}`, {
      returnObjects: true,
    });
    // < 800px screen split pain question to pain_2
    if ((questionnaireType === 'koss' || questionnaireType === 'hoos') && window.innerWidth < 800) {
      questionnaire = questionnaire.map(q => {
        if (q.key === 'PAIN_HOW_OFTEN') {
          q.group = 'pain_2';
        }
        return q;
      });
    }
    const groups = groupBy(questionnaire, item => item.group);

    const parsedGroups = t(`${questionnaireType}:phases`, {
      returnObjects: true,
    })?.map(phase => {
        return {
          title: phase.title,
          description: phase.description,
          phaseKey: phase.key,
          data: groups[phase.key] ? [groups[phase.key]] : undefined,
        };
      })
      .filter(group => group?.data?.length > 0);

    setAllPageCount(parsedGroups.length);
    return parsedGroups;
  };

  const onSubmit = (data, {setSubmitting}) => {};

  const isValid = () => {
    const currentGroup = formGroups?.[currentPage]?.data?.[0]?.filter(
      item => !item.ignore,
    );
    if (!formRef?.current?.values) {
      return false;
    }

    return Boolean(
      currentGroup?.every(
        currentQuestion => formRef?.current?.values[currentQuestion.key],
      ),
    );
  };

  const onTabChange = async tab => {
    if (activeTab !== 'overview' && (await checkForErrors())) {
      return;
    }

    if (activeTab !== 'overview') {
      dispatch(actions.saveAnswers({[activeTab]: getValueswithDefaults()}));
    }

    setCurrentPage(0);
    dispatch(actions.setActiveTab(tab));
    formRef.current.setValues(answers[tab] || {});
  };

  if (!activeTab) {
    return null;
  }

  const RenderSection = ({setFieldValue, values, errors, validateField}) => {
    const section = formGroups[currentPage];
    const item = section.data[0];
    let replace = '';
    if (tabs.find(tab => tab.key === activeTab)?.type === 'eq') {
      const joint = tabs.find(tab => tab.key === activeTab);
      const [, jointSide] = joint.key.split('_');
      replace = t(
          `eq:placeholders.${jointSide.toUpperCase()}_${joint.joint.toUpperCase()}`,
      );
    }

    return (
        <div style={{minHeight: 100, width: '100%'}}>
          <div className={'sectionSeparator'}>
            <p className={'groupTitle'}>{section.title}</p>
            {section.description &&
                section.description.length > 0 && (
                    <DynamicTypography
                        className={'groupDescription'}
                        content={section.description.replace(
                            /\[placeholder\]/g,
                            replace,
                        )}
                    />
                )}
          </div>
          <div className={'questionnaire-container'}>
            <QuestionnaireGroup
                questions={item}
                setFieldValue={setFieldValue}
                validateField={validateField}
                values={values}
                errors={errors}
                layouts={layouts}
            />
          </div>
        </div>
    );
  };

  const onSeValue = (k, v, setFieldValue) => {
    setFieldValue(k, v);

    // check if all questions answered on this section
    const localValues = {
      ...formRef?.current?.values,
      [k]: v,
    };

    const allQuestionsAnswered = formGroups[currentPage]?.data?.[0]?.every(q => q.ignore || Boolean(localValues[q.key]));
    const formRoutes = formRouter();
    if (allQuestionsAnswered &&
        !(
            formRoutes.map(tab => tab.key).indexOf(activeTab) ===
            formRoutes.length - 1 && totalPages === currentPage
        ) &&
        ['BACK_PAIN', 'SHOULDER_PAIN'].indexOf(k) < 0
    ) {
      setTimeout(onNext, 400);
    }
  };

  return (
    <Formik
      initialValues={(answers && answers[activeTab]) || {}}
      validationSchema={() =>
        Yup.lazy(() => {
          return generateValidation();
        })
      }
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
      innerRef={formRef}>
      {({setFieldValue, values, errors, validateField}) => (
        <FormLayout
          nextLabel={t('common:questionnaire.next')}
          submitLabel={t('common:questionnaire.submit')}
          prevLabel={t('common:questionnaire.prev')}
          onBack={onBack}
          onNext={onNext}
          state={changing}
          routes={formRouter()}
          onTabChange={onTabChange}
          activeTab={activeTab}
          currentPage={currentPage}
          totalPages={totalPages}
          isValid={isValid()}>
          {activeTab !== 'overview' && formGroups && (
            <RenderSection setFieldValue={(k, v) => onSeValue(k, v, setFieldValue)} values={values} errors={errors} validateField={validateField} />
          )}
        </FormLayout>
      )}
    </Formik>
  );
};
