import React from "react";
import {getStyle} from "@utils/style";
import {ReactComponent as Check} from '@icons/check.svg';

export const CheckBox = (props) => {
    return (
        <label className="styled-checkbox" style={{
            color: props.value ? getStyle('PRIMARY_COLOR', true) : getStyle('INPUT_PLACEHOLDER_COLOR', true),
            fontSize: getStyle('INPUT_FONT_SIZE', true)
        }}>
            <input type="checkbox" name={props.name} onChange={props.onChange} checked={props.value} />
            <span>
                {props.value && <Check/>}
            </span>
            {props.title}
        </label>
    );
}

CheckBox.defaultValues = {
    value: false
}
