import { takeLatest, put } from 'redux-saga/effects';
import produce from 'immer';
import createAction from '@utils/action-creator';
import axios from "@utils/axios";
import {push} from "react-router-redux";

/**
 * CONSTANTS
 */
export const SET_MODAL = '@app/patient/SET_MODAL';
export const SET_LOADING = '@app/patient/SET_LOADING';
export const GENERATE_LINK = '@app/patient/GENERATE_LINK';
export const GENERATE_LINK_SUCCESS = '@app/patient/GENERATE_LINK_SUCCESS';
export const TERMINATE_PATIENT = '@app/patient/TERMINATE_PATIENT';

const _state = {
    modal: '',
    link: null
};

const reducer = (state = _state, action) => (
    produce(state, draft => {
        switch (action.type) {
            case SET_MODAL:
                draft.modal = action.payload;
                break;
            case GENERATE_LINK_SUCCESS:
                draft.link = action.payload;
                break;
            default:
                break;
        }
    })
);
export default reducer;

export const actions = {
    setModal: (payload) => createAction(SET_MODAL, { payload }),
    generateLink: (payload) => createAction(GENERATE_LINK, { payload }),
    terminatePatient: (payload) => createAction(TERMINATE_PATIENT, { payload }),
};

export const sagas = {
    * generateLink(action) {
        const {
            payload: {
                values,
                cb
            }
        } = action;
        // set defaults
        yield put(createAction(SET_LOADING, { payload: true }));

        try {
            const response = yield (axios.post('/links', values));
            const result = response.data;
            yield put(createAction(GENERATE_LINK_SUCCESS, { payload: result }));

            if (result.type === 'app') {
                yield put(push(`/questionnaire/${result._id}/start`));
            }

            cb && cb.onSuccess && cb.onSuccess();
        } catch (error) {
            console.error('error', error);
            cb.onError(error);
        } finally {
            yield put(createAction(SET_LOADING, { payload: false }));
        }
    },
    * terminate(action) {
        const {
            payload: {
                values,
                cb
            }
        } = action;

        try {
            yield (axios.post(`/patients/${values.patientID}/terminate`, values));
            cb && cb.onSuccess && cb.onSuccess();
        } catch (error) {
            console.error('error', error);
            cb.onError(error);
        }
    }
};

export const watcher = function* w() {
    yield takeLatest(GENERATE_LINK, sagas.generateLink);
    yield takeLatest(TERMINATE_PATIENT, sagas.terminate);
};
