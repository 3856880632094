import React from 'react';
import './assets/style/style.scss';
import {Route, Routes} from "react-router";
import Consent from "@pages/consent";
import {Questionnaire} from "@pages/questionnaire";
import Success from "@components/questionnaire/success";
import {NotFound} from "@pages/not-found";

function App() {
    return (
        <Routes>
            <Route path="/questionnaire">
                <Route path=":token">
                    <Route index element={<Consent/>}/>
                    <Route path="start" element={<Questionnaire/>}/>
                    <Route path=":promId" element={<Consent/>}/>
                </Route>
                <Route path="submit" element={<Success/>}/>
            </Route>
            <Route path="/not-found" element={<NotFound/>}/>
            <Route path="*" element={<NotFound/>}/>
        </Routes>
    );
}

export default App;
