import {combineReducers} from "redux";
import patient from '@store/sagas/patient';
import questionnaire from '@store/sagas/questionnaire';
import { createRouterReducer } from '@lagunovsky/redux-react-router';
import {browserHistory} from "@utils/history";

export default function createReducer() {
    return combineReducers({
        router: createRouterReducer(browserHistory),
        app: combineReducers({
            patient,
            questionnaire,
        })
    });
};
