import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import config from '@constants/index';

const resources = {
    en: {
        common: require('./resources/common-en'),
        eq: require('./resources/eq/en'),
        koss: require('./resources/koss/en'),
        hoos: require('./resources/hoos/en'),
        oxsh: require('./resources/oxsh/en'),
    },
    de: {
        common: require('./resources/common-de'),
        eq: require('./resources/eq/de'),
        koss: require('./resources/koss/de'),
        hoos: require('./resources/hoos/de'),
        oxsh: require('./resources/oxsh/de'),
    },
    fr: {
        common: require('./resources/common-fr'),
        eq: require('./resources/eq/fr'),
        koss: require('./resources/koss/fr'),
        hoos: require('./resources/hoos/fr'),
        oxsh: require('./resources/oxsh/fr'),
    },
    it: {
        common: require('./resources/common-it'),
        eq: require('./resources/eq/it'),
        koss: require('./resources/koss/it'),
        hoos: require('./resources/hoos/it'),
        oxsh: require('./resources/oxsh/it'),
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: config.DEFAULT_LANGUAGE,
        fallbackLng: 'en',
        load: 'languageOnly',

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
