import { all } from 'redux-saga/effects';

import { watcher as uiNavigation } from '@store/sagas/navigation';
import { watcher as patient } from '@store/sagas/patient';
import { watcher as questionnaire } from '@store/sagas/questionnaire';

export default function* root() {
    yield all([
        uiNavigation(),
        patient(),
        questionnaire(),
    ])
}
