import {createStore, compose, applyMiddleware} from "redux";
import createSagaMiddleware from 'redux-saga';
import reducers from "@store/reducers";
import sagas from '@store/sagas/index';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router';
import {browserHistory} from "@utils/history";

const routerMiddleware = createRouterMiddleware(browserHistory)

let store = null;
export function configureStore() {
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [sagaMiddleware, routerMiddleware];

    const composeEnhancers =
        process.env.NODE_ENV !== 'production' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
            window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                name: 'MyApp', actionsBlacklist: ['REDUX_STORAGE_SAVE']
            }) : compose;

    const store = createStore(reducers(), composeEnhancers(
        applyMiddleware(...middleware),
    ));
    sagaMiddleware.run(sagas);

    return store;
}

export function getStore() {
    return store;
}

export default store;
