import React from 'react';

export const NumberInput = ({value, placeholder, onChange, error, style}) => {
  return (
    <div>
      <input
        style={[
          styles.textInput,
          styles.standAloneTextInput,
          style ? style : undefined,
          error ? styles.withError : undefined,
        ]}
        placeholder={placeholder}
        autoCorrect={false}
        spellCheck={false}
        autoCapitalize="none"
        value={value}
        placeholderTextColor={error ? 'red' : '#666'}
        onChangeText={text => onChange(+text.replace(/[^0-9]/g, ''))}
      />
    </div>
  );
};

const styles = {};
