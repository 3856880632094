import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux';
import {configureStore} from "@app/store";
import {browserHistory} from '@utils/history';
import {setupAxios} from "@utils/axios";
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import './i18n';
import App from '@app/App';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

const store = configureStore();
setupAxios();

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <StrictMode>
        <Provider store={store}>
            <ReduxRouter
                history={browserHistory}
                store={store}
                children={<App/>}
            />
        </Provider>
    </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
