import React from 'react';
import styled, { css } from 'styled-components';
import {getStyle} from "@utils/style";

const IconContainer = styled.div`
    background-color: #3D3D3D;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    height: ${window.innerWidth < 800? '40px': '56px'};
    width: ${window.innerWidth < 800? '40px': '56px'};
    display: flex;
    
    ${props => props.valid && !props.disabled && css`
      background-color: ${getStyle('PRIMARY_COLOR', true)};
    `}
`;

const IconImage = styled.img`
    height: ${window.innerWidth < 800? '16px': '20px'};
    
    ${props => props.disabled && css`
      opacity: 0.5;
    `}
`;

const Label = styled.p`
    margin: 0 ${window.innerWidth < 800? '10px': '15px'};
    font-size: ${window.innerWidth < 800? '16px': '20px'};
    color: #000;
`;

const Button = styled.button`
    flex-direction: row;
    align-items: center;
    background: none;
    margin: 0;
    padding: 0;
    border: none;
    display: flex;
    cursor: pointer;
`;

export const NavigationButtonV2 = ({
  disabled,
  onPress,
  label,
  valid,
  direction,
}) => {
  return (
    <Button
      disabled={disabled}
      onClick={onPress}>
      {direction === 'left' && (
        <IconContainer
            valid={valid}
            disabled={disabled}
        >
          <IconImage
            src={require('./../assets/icons/left-icon.png')}
            disabled={disabled}
          />
        </IconContainer>
      )}
      <Label>{label}</Label>
      {direction === 'right' && (
        <IconContainer
            valid={valid}
            disabled={disabled}
        >
          <IconImage
            src={require('./../assets/icons/right-icon.png')}
            disabled={disabled}
          />
        </IconContainer>
      )}
    </Button>
  );
};
