import React from "react";
import {Button} from "@components/form/button";

export const Type1 = ({group, g, values, setFieldValue}) => {
    return (
        <div className="type-1-questionnaire">
            {g.options[0].options.map(item => {
                return <Button key={g.key + '_' + item.key} title={item.label}
                               onClick={() => setFieldValue(group.key, item.key)}
                               type={values[group.key] && values[group.key] === item.key ? 'primary' : 'secondary'}/>
            })}
        </div>
    );
}
