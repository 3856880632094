import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {
    Flow1Container,
    OptionButton,
    OptionButtonText,
    QuestionDiv,
    QuestionNavBtn
} from "@components/questionnaire/flow-1-components";

const QUESTIONS_AREA = window.innerHeight - 440;
const QUESTION_AREA = 96;
const MAX_PARTS = Math.floor(QUESTIONS_AREA / QUESTION_AREA);

export const Flow1 = ({group, values, setFieldValue, errors}) => {
    const [parts, setParts] = useState(undefined);
    const [activePart, setActivePart] = useState(0);
    const [isChanging, setIsChanging] = useState(false);

    useEffect(() => {
        let splitPart = MAX_PARTS;
        if (group.options?.[0]?.size) {
            splitPart = Math.floor(
                (QUESTIONS_AREA - group.options?.[0]?.size) / QUESTION_AREA,
            );
        }

        const splitParts = _.chunk(group.options, window.innerWidth < 800? 1 : splitPart);
        setParts(splitParts);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onAnswer = (key, value) => {
        setFieldValue(key, value);

        if (questionsAnswered({[key]: value})) {
            setTimeout(() => {
                if (activePart + 1 < parts.length) {
                    setIsChanging(true);
                    setTimeout(() => {
                       setActivePart(activePart + 1);
                       setIsChanging(false);
                    }, 650);
                }
            }, 300);
        }
    };

    const Option = ({questionKey, option}) => {
        return (
            <OptionButton
                isActive={values[questionKey] && values[questionKey] === option.key}
                onClick={() => onAnswer(questionKey, option.key)}>
                <div className={'radio'}/>
                <OptionButtonText
                    isActive={values[questionKey] && values[questionKey] === option.key}>
                    {option.label}
                </OptionButtonText>
            </OptionButton>
        );
    };

    const Question = ({question}) => {
        return (
            <QuestionDiv
                active={values[question.key]}
                error={errors[question.key]}>
                <div className={'titleContainer'}>
                    <p>{question.title}</p>
                </div>
                <div className={'optionsContainer'}>
                    {question.options.map(option => {
                        return (
                            <Option
                                key={`${question.key}_${option.key}`}
                                questionKey={question.key}
                                option={option}
                            />
                        );
                    })}
                </div>
            </QuestionDiv>
        );
    };

    const questionsAnswered = (currentAnswer) => {
        let answers = Object.assign(values || {}, currentAnswer || {});
        return Boolean(
            parts?.[activePart]?.every(
                currentQuestion => answers?.[currentQuestion.key],
            ),
        );
    };

    const QuestionNavigator = () => {
        return (
            <div className={'questionNavigatorContainer'}>
                <QuestionNavBtn
                    disabled={activePart === 0}
                    onClick={() => {
                        if (activePart > 0) {
                            setIsChanging(true);
                            setTimeout(() => {
                                setActivePart(activePart - 1);
                                setIsChanging(false);
                            }, 650);
                        }
                    }}>
                    <img className={'paginationBtnIcon'}
                         alt={'up'}
                         src={require('./../../assets/icons/up-icon.png')}
                    />
                </QuestionNavBtn>
                <div className={'paginationText'}>
                    <span className={'paginationFirst'}>{activePart + 1}</span>
                    <span className={'paginationSlash'}>/</span>
                    <span className={'paginationLast'}>{parts.length}</span>
                </div>
                <QuestionNavBtn
                    active={questionsAnswered()}
                    disabled={activePart + 1 >= parts.length || !questionsAnswered()}
                    onClick={() => {
                        if (activePart + 1 < parts.length) {
                            setIsChanging(true);
                            setTimeout(() => {
                                setActivePart(activePart + 1);
                                setIsChanging(false);
                            }, 650);
                        }
                    }}>
                    <img className={'paginationBtnIcon'}
                         alt={'down'}
                         src={require('./../../assets/icons/down-icon.png')}
                    />
                </QuestionNavBtn>
            </div>
        );
    };

    const Separator = ({question}) => {
        return (
            <div className={'separatorContainer'}>
                <p className={'separatorText'}>{question.title}</p>
            </div>
        );
    };

    if (!parts || !parts.length) {
        return null;
    }

    return (
        <Flow1Container
            fixedHeight={!(parts[activePart]?.[0]?.height === 'auto' ||
                parts[activePart]?.[0]?.size ||
                parts[activePart]?.[0]?.type === 'flow_1_desc_separator')}>
            <div className={`questionsContainer ${parts.length > 1 ? isChanging ? 'questionsOut': 'questionsIn' : ''}`}>
                {parts[activePart].map(question => {
                    if (question.type === 'flow_1_desc_separator') {
                        return <Separator key={question.key} question={question}/>;
                    } else {
                        return <Question key={question.key} question={question}/>;
                    }
                })}
            </div>
            {parts.length > 1 && <QuestionNavigator/>}
        </Flow1Container>
    );
};
