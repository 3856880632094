import styled, {css} from 'styled-components';

export const OptionButton = styled.button`
    flex-direction: row;
    align-items: center;
    background-color: #F4F4F4;
    border: 1px #EFEFEF solid;
    min-height: 36px;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: 5px;
    margin-right: 5px;
    flex: 1;
    display: flex;
    
    ${window.innerWidth < 800 && css`
        margin-bottom: 5px;
        margin-top: 5px;
    `}
    
    ${props => props.isActive && css`
        background-color: #5FCD89;
    `}
`;

export const OptionButtonText = styled.p`
    font-size: 15px;
    color: #000;
    flex: 1;
    padding-right: 5px;
    font-weight: bold;
    display: inline-flex;
    text-align: left;
    
    ${props => props.isActive && css`
        color: #ffffff;
    `}
`;

export const QuestionDiv = styled.div`
    width: ${window.innerWidth < 800? 'calc(100% - 30px)': 'calc(100vw - 250px)'};
    min-width: ${window.innerWidth < 800? 'auto': '750px'};
    margin-left: ${window.innerWidth < 800? '0': '6px'};
    margin-right: 6px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border: 1px #DDDDDD solid;
    border-radius: 6px;
    
    ${props => props.active && css`
        border-color: #5FCD89;
    `}
    
    ${props => props.error && css`
        border-color: #E41E13;
    `}
`;

export const QuestionNavBtn = styled.button`
    border-radius: 6px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: ${window.innerWidth < 800? '40px': '48px'};
    height: ${window.innerWidth < 800? '40px': '48px'};
    background-color: #3D3D3D;
    border: none;
    
    ${props => props.active && css`
        background-color: #37ab49;
    `}
    
    ${props => props.disabled && css`
        background-color: rgba(61, 61, 61, 0.5);
    `}
`;

export const Flow1Container = styled.div`
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    
    ${props => props.fixedHeight && window.innerWidth > 800 && css`
        min-height: calc(100vh - 280px);
    `}
`;
