import { takeLatest, put } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import createAction from '@utils/action-creator';

export const NAVIGATE = '@app/ui/NAVIGATE';

export const actions = {
    navigate: (payload) => createAction(NAVIGATE, { payload }),
};

export const sagas = {
    * navigate(action) {
        const {
            payload: to
        } = action;
        yield put(push(to));
    },
};

export const watcher = function* w() {
    yield takeLatest(NAVIGATE, sagas.navigate);
};
